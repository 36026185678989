import React, { useState, useEffect } from "react"
import { Form, Empty } from "antd"
import { Button } from "reactstrap"
import Auxiliary from "util/Auxiliary"
import { RewardListAPIResponse } from "util/types/types"
import RewardsCard from "components/Ads/RewardsCard"
import {
  getRewardsList,
  createOrUpdateReward,
  deleteReward,
} from "clientServices/adsRewardsService"
import { RouteComponentProps } from "react-router-dom"
import Chips from "components/CommonFilter/chips"
import RewardsForm from "components/Ads/RewardsForm"

interface Filter {
  currentPage: number
  pageSize: number
  status?: string
}

const ManageRewards: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const urlQueryParams = new URLSearchParams(location.search)
  const initialCurrentPage = parseInt(
    urlQueryParams.get("currentPage") ?? "1",
    10
  )
  const initialPageSize = parseInt(urlQueryParams.get("pageSize") ?? "100", 10)

  if (!urlQueryParams.get("currentPage")) {
    urlQueryParams.set("currentPage", "1")
  }
  if (!urlQueryParams.get("pageSize")) {
    urlQueryParams.set("pageSize", "100")
    history.push(`${location.pathname}?${urlQueryParams.toString()}`)
  }

  const [loading, setLoading] = useState(false)
  const [rewardsList, setRewardsList] = useState<RewardListAPIResponse[]>([])
  const [isFormVisible, setIsFormVisible] = useState(false)
  const [editingReward, setEditingReward] =
    useState<RewardListAPIResponse | null>(null)
  const [form] = Form.useForm()
  const [showFilter, setShowFilter] = useState<boolean>(false)

  const [filters, setFilters] = useState<Filter>(() => {
    const activeParam = urlQueryParams.get("status")
    return {
      currentPage: initialCurrentPage,
      pageSize: initialPageSize,
      ...(activeParam && { status: activeParam }),
    }
  })

  const fetchRewardsData = async (payload: Filter) => {
    try {
      setLoading(true)
      const res = await getRewardsList(payload)
      setRewardsList(res.data)
    } catch (error) {
      console.error("Error fetching rewards list:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchRewardsData(filters)
  }, [])

  const toggleStatus = async (index: number, reward: RewardListAPIResponse) => {
    setRewardsList(prev =>
      prev.map((item, i) =>
        i === index ? { ...item, active: !item.active } : item
      )
    )
    await createOrUpdateReward({ ...reward, active: !reward.active })
  }

  const handleOpenForm = (reward?: RewardListAPIResponse) => {
    setEditingReward(reward || null)
    form.setFieldsValue(
      reward || { name: "", ad_count: "", jems_count: "", active: true }
    )
    setIsFormVisible(true)
  }

  const handleDelete = async (reward: RewardListAPIResponse) => {
    try {
      await deleteReward(reward.id)
      setRewardsList(rewards =>
        rewards.filter(individualReward => reward.id !== individualReward.id)
      )
    } catch (err: any) {
      console.log("Delete failed")
    }
  }

  const handleSave = async (values: RewardListAPIResponse) => {
    try {
      const payload = {
        id: editingReward?.id,
        name: values.name,
        ad_count: +values.ad_count,
        jems_count: +values.jems_count,
        active: values.active,
      }

      await createOrUpdateReward(payload)

      if (editingReward) {
        setRewardsList(prev =>
          prev.map(item =>
            item.id === editingReward.id ? { ...item, ...values } : item
          )
        )
      } else {
        await fetchRewardsData(filters)
      }
      setIsFormVisible(false)
    } catch (error) {
      console.error("Validation failed:", error)
    }
  }

  const handleCancelForm = () => {
    setIsFormVisible(false)
  }

  const _removeFilter = async (filterKey: keyof Filter) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete(filterKey)
    history.push(`${location.pathname}?${queryParams.toString()}`)
    const updatedFilters = { ...filters }
    delete updatedFilters[filterKey]
    setFilters(updatedFilters)
    // await fetchRewardsData(updatedFilters)
  }

  const _handleFilterChange = (value: string, type: keyof Filter) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set("currentPage", "1")
    queryParams.set(type, String(value))
    history.push(`${location.pathname}?${queryParams.toString()}`)

    setFilters(prevFilters => ({
      ...prevFilters,
      [type]: value,
      currentPage: 1,
    }))
  }

  const _toggleFilters = () => {
    setShowFilter(!showFilter)
  }

  const _closeFilters = async () => {
    // await fetchRewardsData(filters)
    setShowFilter(false)
  }

  const filteredRewards = () => {
    if (!showFilter && filters.status) {
      const active = filters.status === "active"
      return rewardsList.filter(reward => reward.active === active)
    }
    return rewardsList
  }

  return (
    <>
      {isFormVisible && (
        <RewardsForm
          reward={editingReward}
          onSave={handleSave}
          onCancel={handleCancelForm}
        />
      )}
      {!isFormVisible && (
        <Auxiliary loading={loading}>
          <Chips
            showFilters={showFilter}
            filters={filters}
            search={_closeFilters}
            removeFilter={_removeFilter}
            handleFilterChange={_handleFilterChange}
            type="rewards_streak"
            toggleFilters={_toggleFilters}
          />
          <div>
            <div className="d-flex justify-content-end my-2">
              <Button
                color="primary"
                className="d-flex"
                onClick={() => handleOpenForm()}
              >
                <i className="fa fa-plus mx-1 my-1" />
                Create Reward
              </Button>
            </div>

            {/* Rewards List */}
            {filteredRewards().length === 0 && <Empty />}
            <div className="p-3 d-flex flex-wrap gap-3">
              {filteredRewards().map((reward, index) => (
                <RewardsCard
                  key={reward.id}
                  data={reward}
                  onToggleStatus={() => toggleStatus(index, reward)}
                  onEdit={() => handleOpenForm(reward)}
                  onDelete={() => handleDelete(reward)}
                />
              ))}
            </div>
          </div>
        </Auxiliary>
      )}
    </>
  )
}

export default ManageRewards
