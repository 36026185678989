import Axios from "axios"
import toastr from "toastr"
import { IPLBulkFilterPayload } from "util/types/types"

interface DownloadPayload {
  location: string
  csvId: string
  sublocation: string
}

export const uploadIplCampaignCsv = async (formData: FormData) => {
  try {
    const url = `/api/ipl-campaign/upload-csv`
    const response = await Axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    toastr.success("CSV uploaded successfully for IPL Campaign!")
    return response.data
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || String(error)
    toastr.error("Error uploading CSV for IPL Campaign: " + errorMessage)
    throw error
  }
}

export const getIPLJemsCSV = async (payload: DownloadPayload) => {
  try {
    const location = encodeURIComponent(
      `${payload.sublocation}/${payload.csvId}/${payload.location}`
    )
    const data = await Axios.get(
      `/api/ipl-campaign/csv/download?csvId=${payload.csvId}&location=${location}`
    )
    return data.data
  } catch (error) {
    toastr.error("Unable to get csv " + String(error))
    throw error
  }
}

export const getBulkReportList = async (payload: IPLBulkFilterPayload) => {
  try {
    const filters = payload
    const offset = filters.currentPage ? parseInt(filters.currentPage) - 1 : 0
    const limit = filters.pageSize ? filters.pageSize : 10
    const data = await Axios.post(
      `/api/reports/getReportsByFilter?offset=${offset}&limit=${limit}`,
      {
        filters: payload,
      }
    )
    return data.data
  } catch (error) {
    throw error
  }
}
