import React, { useState, useEffect } from "react"
import { Table } from "reactstrap"
import {
  getTransactionInvoice,
  getTransactionList,
} from "clientServices/joshResellerService"
import { Pagination } from "antd"
import type { PaginationProps } from "antd"
import { TbDownload } from "react-icons/tb"
import { Chip } from "@mui/material"
import dayjs from "dayjs"
import { useSelector, TypedUseSelectorHook } from "react-redux"
import Auxiliary from "util/Auxiliary"
import {
  ResellerTransactionsAPIResponse,
  CurrentUserRootState,
} from "util/types/types"

interface Filter {
  currentPage: number
  pageSize: number
}

interface Payload {
  managerId?: string
  filters: Filter
  unassigned?: boolean
}

interface TransactionProps {
  managerId: string
}

const useTypedSelector: TypedUseSelectorHook<CurrentUserRootState> = useSelector

const Transactions: React.FC<TransactionProps> = ({
  managerId = "default",
}) => {
  const [transactionList, setTransactionList] = useState<
    ResellerTransactionsAPIResponse[]
  >([])

  const [filters, setFilters] = useState<Filter>({
    currentPage: 1,
    pageSize: 10,
  })

  const [transactionsTotal, setTransactionsTotal] = useState(0)
  const currentUser = useTypedSelector(state => state.currentUser)
  const [loading, setLoading] = useState<boolean>(false)
  let managerID = ""

  if (managerId === "default") {
    managerID = String(currentUser?.currentUser?.data?.userId) || ""
  } else {
    managerID = managerId
  }

  useEffect(() => {
    fetchData({ managerId: managerID, filters: filters }, true)
  }, [])

  const fetchData = async (payload: Payload, initial = false) => {
    try {
      setLoading(true)
      const res = await getTransactionList(payload)
      if (initial) {
        setTransactionsTotal(parseInt(res.data.count))
      }
      setTransactionList(res.data.response)
    } catch (error) {
      console.error("Error fetching reseller list:", error)
    } finally {
      setLoading(false)
    }
  }

  const handlePageChange: PaginationProps["onChange"] = async pageNumber => {
    const payload: Payload = {
      managerId: managerID,
      filters: {
        ...filters,
        currentPage: pageNumber,
      },
    }
    setFilters(payload.filters)
    const res = await getTransactionList(payload)
    await fetchData(payload)
  }

  const downloadFile = (invoiceFile: {
    data: string
    invoiceId: string
    format?: string
  }) => {
    const fileFormat = invoiceFile.format || "jpeg"
    const anchorElement = document.createElement("a")
    anchorElement.href = `data:application/octet-stream;base64,${invoiceFile.data}`
    anchorElement.download = `${invoiceFile.invoiceId}.${fileFormat}`
    anchorElement.click()
  }

  const downloadInvoice = async (
    transactionItem: ResellerTransactionsAPIResponse
  ) => {
    const res = await getTransactionInvoice({
      invoiceId: transactionItem.transaction_id,
      location: transactionItem.invoice_url,
    })
    const fileFormat = transactionItem.invoice_url.split(".").pop() // Extract file extension
    downloadFile({
      data: res.data,
      invoiceId: transactionItem.transaction_id,
      format: fileFormat,
    })
  }

  // Function to return Chip color based on status
  const getStatusChip = (status: string) => {
    switch (status.toLowerCase()) {
      case "success":
        return (
          <Chip
            variant="outlined"
            label="Success"
            color="success"
            size="small"
            style={{ borderRadius: "8px" }}
          />
        )
      case "failed":
        return (
          <Chip
            variant="outlined"
            label="Failed"
            color="error"
            size="small"
            style={{ borderRadius: "8px" }}
          />
        )
      case "pending":
        return (
          <Chip
            variant="outlined"
            label="Pending"
            color="warning"
            size="small"
            style={{ borderRadius: "8px" }}
          />
        )
      default:
        return (
          <Chip
            variant="outlined"
            label="Unknown"
            color="default"
            size="small"
            style={{ borderRadius: "8px" }}
          />
        )
    }
  }

  return (
    <Auxiliary loading={loading}>
      <main className="p-sm-4">
        <div
          className="mt-md-3 bg-white"
          style={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <Table hover responsive>
            <thead>
              <tr style={{ backgroundColor: "#f9fafb", color: "#6b7280" }}>
                <th>Transaction ID</th>
                <th>Reseller ID</th>
                <th>Modified Time</th>
                <th>Jems Transferred</th>
                <th>Status</th>
                <th>File</th>
              </tr>
            </thead>
            <tbody>
              {transactionList.map(transactionItem => {
                return (
                  <tr key={transactionItem.transaction_id}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {transactionItem.transaction_id}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {transactionItem.reseller_id}
                    </td>
                    <td>
                      {dayjs(Number(transactionItem.modified_time)).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </td>
                    <td style={{ color: "#f59e0b" }}>
                      <img
                        src="https://saz.myjosh.in/stream-az/prod-ugc-contents/static-images/categories/coin.png"
                        alt="coin-icon"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "8px",
                        }}
                      />
                      {transactionItem.platform_currency_count}
                    </td>
                    <td>{getStatusChip(transactionItem.status)}</td>
                    <td>
                      {transactionItem.invoice_url && (
                        <TbDownload
                          title="Download"
                          onClick={() => downloadInvoice(transactionItem)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>

          <div className="d-flex justify-content-end pb-3">
            <Pagination
              className="mt-md-3"
              onChange={handlePageChange}
              current={filters.currentPage}
              total={transactionsTotal < 10000 ? transactionsTotal : 10000}
              pageSize={filters.pageSize}
              showSizeChanger={false}
            />
          </div>
        </div>
      </main>
    </Auxiliary>
  )
}

export default Transactions
