import Axios from "axios"
import toastr from "toastr"

interface Filter {
  currentPage: number
  pageSize: number
  status?: string
}

interface RewardData {
  id?: string
  name: string
  ad_count: number
  jems_count: number
  active: boolean
}

export const getRewardsList = async (payload: Filter) => {
  try {
    const offset = payload.currentPage ? payload.currentPage - 1 : 0
    const limit = payload.pageSize || 10

    const queryParams = {
      limit: limit,
      offset: offset,
      ...(payload.status && { active: payload.status === "active" }),
    }

    const url = `/api/rewards/list`
    const response = await Axios.get(url, { params: queryParams })

    return response.data
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || String(error)
    toastr.error("Error getting Rewards List: " + errorMessage)
    throw error
  }
}

export const createOrUpdateReward = async (payload: RewardData) => {
  try {
    const response = await Axios.post("/api/rewards/create", payload)
    toastr.success(
      "Reward " + (payload.id ? "updated" : "created") + " successfully!"
    )
    return response.data
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || String(error)
    toastr.error(
      "Error " +
        (payload.id ? "updating" : "creating") +
        " reward: " +
        errorMessage
    )
    throw error
  }
}

export const deleteReward = async (id: string) => {
  try {
    const response = await Axios.delete(`/api/rewards/delete/id=${id}`)
    toastr.success("Reward deleted successfully!")
    return response.data
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || String(error)
    toastr.error("Error deleting reward: " + errorMessage)
    throw error
  }
}