import React, { useState, useEffect } from "react"
import {
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material"
import { TbDownload } from "react-icons/tb"
import {
  getIPLJemsCSV,
  uploadIplCampaignCsv,
  getBulkReportList,
} from "clientServices/iplCampaignService"
import { Pagination, PaginationProps, Tooltip } from "antd"
import { IPLBulkFilterPayload } from "util/types/types"
import Auxiliary from "util/Auxiliary"
import { RouteComponentProps } from "react-router-dom"

interface Report {
  uuid: string
  operation: string
  status: string
  uploaded_by: string
  entity_type: string
  csv_name: string
  created_on: string
  debug_info: string
  success_count: number
  failed_count: number
}

const AddJems: React.FC<RouteComponentProps> = ({ history, location }) => {
  const urlQueryParams = new URLSearchParams(location.search)
  const [error, setError] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)
  const [success, setSuccess] = useState<string>("")
  const [reportList, setReportList] = useState<Report[]>([])
  const [filters, setFilters] = useState<IPLBulkFilterPayload>({
    orderByField: "createdOn",
    order: "desc",
    searchType: "entityType",
    searchText: "IPL_CAMPAIGN",
    pageSize: urlQueryParams.get("pageSize") ?? "10",
    currentPage: urlQueryParams.get("currentPage") ?? "1",
  })
  const [reportsTotal, setReportsTotal] = useState(1)

  if (!urlQueryParams.get("currentPage")) {
    urlQueryParams.set("currentPage", filters.currentPage)
  }
  if (!urlQueryParams.get("pageSize")) {
    urlQueryParams.set("pageSize", filters.pageSize)
    history.push(`${location.pathname}?${urlQueryParams.toString()}`)
  }

  useEffect(() => {
    fetchData(filters)
  }, [])

  const fetchData = async (payload: IPLBulkFilterPayload) => {
    try {
      const data = await getBulkReportList(payload)
      setReportList(data.reportList || [])
      setReportsTotal(data.count)
    } catch (error) {
      console.error("Error fetching report list:", error)
    } finally {
      setLoading(false)
    }
  }

  const handlePageChange: PaginationProps["onChange"] = async pageNumber => {
    setLoading(true)
    const newFilters = { ...filters, currentPage: String(pageNumber) }
    setFilters(newFilters)
    const queryParams = new URLSearchParams(location.search)
    queryParams.set("currentPage", String(pageNumber))
    history.push(`${location.pathname}?${queryParams.toString()}`)
    await fetchData(newFilters)
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) return

    const formData = new FormData()
    formData.append("file", file)

    try {
      const response = await uploadIplCampaignCsv(formData)
      if (!response.success) {
        throw new Error("File upload failed")
      }

      setSuccess("File uploaded successfully!")
      setError("")

      const newFilters = { ...filters, currentPage: "1" }

      fetchData(newFilters)
    } catch (err: any) {
      setError(err.message || "An error occurred during file upload.")
      setSuccess("")
    }
  }

  const downloadFile = (csvFile: {
    data: string
    csvId: string
    format?: string
  }) => {
    const fileFormat = csvFile.format || "csv"
    const anchorElement = document.createElement("a")
    anchorElement.href = `data:application/octet-stream;base64,${csvFile.data}`
    anchorElement.download = `${csvFile.csvId}.${fileFormat}`
    anchorElement.click()
  }

  const handleDownload = async (
    uuid: string,
    csv_name: string,
    entity_type: string
  ) => {
    try {
      const res = await getIPLJemsCSV({
        csvId: uuid,
        location: csv_name,
        sublocation: entity_type,
      })
      const fileFormat = csv_name.split(".").pop() || "csv"
      downloadFile({
        data: res.data,
        csvId: uuid,
        format: fileFormat,
      })
    } catch (error) {
      console.error("Download error", error)
    }
  }

  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        Add Jems - IPL Campaign
      </Typography>
      <input
        accept=".csv"
        style={{ display: "none" }}
        id="upload-csv"
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor="upload-csv">
        <Button variant="contained" color="primary" component="span">
          Upload CSV
        </Button>
      </label>
      {error && (
        <Typography variant="body1" color="error" mt={2}>
          {error}
        </Typography>
      )}
      {success && (
        <Typography variant="body1" color="primary" mt={2}>
          {success}
        </Typography>
      )}
      <Auxiliary loading={loading}>
        {reportList.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Uploaded By</TableCell>
                  <TableCell>Uploaded On</TableCell>
                  <TableCell>Download</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportList.map((report, index) => (
                  // <Tooltip
                  //   title={`Success: ${report.success_count || 0}, Failed: ${
                  //     report.failed_count || 0
                  //   }\nDetails: ${report.debug_info || "N/A"}`}
                  //   key={index}
                  // >
                    <TableRow>
                      <TableCell>
                        {report.status === "SUCCESS" ? (
                          <Chip label="SUCCESS" color="success" />
                        ) : (
                          <Chip label="PARTIAL_SUCCESS" color="warning" />
                        )}
                      </TableCell>
                      <TableCell>{report.uploaded_by}</TableCell>
                      <TableCell>
                        {new Date(report.created_on).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <TbDownload
                          title="Download"
                          onClick={() =>
                            handleDownload(
                              report.uuid,
                              report.csv_name,
                              report.entity_type
                            )
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </TableCell>
                    </TableRow>
                  // </Tooltip>
                ))}
              </TableBody>
            </Table>
            <div className="d-flex justify-content-end pb-3">
              <Pagination
                className="mt-md-3"
                onChange={handlePageChange}
                current={parseInt(filters.currentPage)}
                total={reportsTotal < 10000 ? reportsTotal : 10000}
                pageSize={parseInt(filters.pageSize)}
                showSizeChanger={false}
              />
            </div>
          </TableContainer>
        )}
      </Auxiliary>
    </Box>
  )
}

export default AddJems
