import Axios from "axios"
import toastr from "toastr"

interface StaticData {
  id?: number
  name?: string
  type?: string
  value: string
}

interface Filter {
  currentPage: number
  pageSize: number
  propertyName?: string
}

export const getStaticDataList = async (filters: Filter) => {
  try {
    const offset = filters.currentPage ? filters.currentPage - 1 : 0
    const limit = filters.pageSize ? filters.pageSize : 10
    const property_name = filters.propertyName ? filters.propertyName : null

    let url = `/api/static-data?limit=${limit}&offset=${offset}`
    if (property_name) {
      url += `&property_name=${property_name}`
    }

    const data = await Axios.get(url)
    return data.data
  } catch (error) {
    toastr.error("Error getting Static Data List: " + String(error))
    throw error
  }
}

export const createStaticData = async (payload: StaticData) => {
  try {
    const response = await Axios.post(`/api/static-data`, payload)
    toastr.success("Static Data created successfully!")
    return response.data
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message || "Unknown error";
    toastr.error("Error creating Static Data: " + errorMessage);
    throw error
  }
}

export const updateStaticData = async (
  id: number,
  payload: Partial<StaticData>
) => {
  try {
    const response = await Axios.put(`/api/static-data/${id}`, payload)
    toastr.success("Static Data updated successfully!")
    return response.data
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message || "Unknown error";
    toastr.error("Error updating Static Data: " + errorMessage)
    throw error
  }
}

