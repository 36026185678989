import React, { useEffect, useState } from "react"
import "./style.css"
import _ from "lodash"

export default function EditableList(props: any) {
    const { disabled, placeholder = "", customStyles } = props;
    const [ listItems, setListItems] = useState(props?.listItems || [])
    const [ newItem, setNewItem] = useState("")
    const [ focus, setFocus] = useState(false)

    const _remove_item = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: any) => {
        if (disabled) return;
        event.stopPropagation()
        let newListItems = _.cloneDeep(listItems)
        newListItems = newListItems.filter( (item: any) => {
            return item !== value
        })
        setListItems(newListItems)
        props.onChange(newListItems)
    }

    const randomText = () => {
            const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < 7; i++) { result += chars.charAt(Math.floor(Math.random() * chars.length));}
            return result;        
    }

    const input_tag_id = randomText()

    const _changeWidth = (inputTag: any) => {
        const input = document.getElementById(input_tag_id);
        if (input && input.style) {
            input.style.width = `${(input.scrollWidth || 0) + 5}px`;
        }
    }

    const _addNewItem = () => {
        if (disabled || newItem.trim() === "") return;
        let newListItems = _.cloneDeep(listItems)
        newListItems.push(newItem)
        newListItems = newListItems.filter((item: string) => item !="")
        setListItems( newListItems )
        setNewItem("")
        props.onChange(newListItems)
    }

    useEffect(()=>{
        const addItem = setTimeout( () => _addNewItem(),2000)
        return () => clearTimeout(addItem)
    },[newItem])

    // console.log(listItems)
    return(
        <div className={`list-wrapper ${focus ? 'active' : ''}`}
        style={{
            ...(disabled && { opacity: 0.5, cursor: "not-allowed" }),
            ...(customStyles && {...customStyles})
          }}
        tabIndex={0}
        onFocus={e => {setFocus(true);document.getElementById(input_tag_id)?.focus()}}
        onBlur={e => {setFocus(false);;document.getElementById(input_tag_id)?.blur()}}>

            {!focus && listItems.length === 0 && !newItem && (
                 <div style={{ opacity: 0.3, fontSize: "16px", paddingLeft:"4px" }}>
                    {placeholder}
                </div>
                )}
           
            {
                listItems?.map( (item: string, i: React.Key | null | undefined) => {
                    return(
                        <div key={i} className="list-item">
                            <div className="item-value">{item}</div>
                            <div className="item-remove" onClick={(e) => _remove_item(e,item)}>
                                <i className="fas fa-times" />
                            </div>
                        </div>
                    )
                })
            }
{!disabled && (
            <input type="text" className="item-input" 
                id={input_tag_id}
                value={newItem}
                onInput={tag => _changeWidth(tag)}
                onChange={(event) => setNewItem(event.target.value)}
                onKeyDown={e => { e.key === 'Enter' ?  _addNewItem() : "" }}
            />
)}
        </div>
    )
}