import React, { useState } from "react"
import { Modal, Form, Input, Select, Button } from "antd"
import ReactJson from "react-json-view"
import EditableList from "components/EditableList/editableList"
import { StaticProperty } from "util/types/types"

const { Option } = Select

interface PropertyModalProps {
  visible: boolean
  currentProperty: StaticProperty
  onCancel: () => void
  onSave: () => void
  setCurrentProperty: (property: StaticProperty) => void
}

const PropertyModal: React.FC<PropertyModalProps> = ({
  visible,
  currentProperty,
  onCancel,
  onSave,
  setCurrentProperty,
}) => {
  const [isRawMode, setIsRawMode] = useState(false)

  const handleRawChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentProperty({
      ...currentProperty,
      value: e.target.value,
    })
  }

  // Toggle between raw JSON mode and ReactJson mode
  const toggleMode = () => {
    if (isRawMode) {
      try {
        // Validate and parse JSON when switching back from raw mode
        const parsedJson = JSON.parse(currentProperty.value as string)
        setCurrentProperty({
          ...currentProperty,
          value: JSON.stringify(parsedJson, null, 2),
        })
      } catch (error) {
        alert("Invalid JSON format")
        return
      }
    }
    setIsRawMode(!isRawMode)
  }

  return (
    <Modal
      title={
        currentProperty.id != undefined ? "Edit Property" : "Add New Property"
      }
      open={visible}
      onCancel={onCancel}
      onOk={onSave}
      centered
      bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          <Input
            value={currentProperty.name}
            onChange={e =>
              setCurrentProperty({
                ...currentProperty,
                name: e.target.value,
              })
            }
            disabled={currentProperty.id != undefined}
          />
        </Form.Item>
        <Form.Item label="Type">
          <Select
            value={currentProperty.type}
            onChange={value =>
              setCurrentProperty({
                ...currentProperty,
                type: value,
                value: value === "list" ? [] : value === "json" ? "{}" : "",
              })
            }
            disabled={currentProperty.id != undefined}
          >
            <Option value="text">Text</Option>
            <Option value="json">JSON</Option>
            <Option value="list">List</Option>
          </Select>
        </Form.Item>
        {currentProperty.type === "text" && (
          <Form.Item label="Value">
            <Input
              value={currentProperty.value}
              onChange={e =>
                setCurrentProperty({
                  ...currentProperty,
                  value: e.target.value,
                })
              }
            />
          </Form.Item>
        )}
        {currentProperty.type === "json" && (
          <Form.Item label="Value">
            <div style={{ marginBottom: "10px" }}>
              <Button onClick={toggleMode}>
                {isRawMode ? "Switch to JSON Editor" : "Switch to Raw JSON"}
              </Button>
            </div>
            {isRawMode ? (
              <Input.TextArea
                value={currentProperty.value}
                onChange={handleRawChange}
                autoSize = {{ minRows: 3, maxRows: 10 }}
              />
            ) : (
              <ReactJson
                src={JSON.parse((currentProperty.value as string) || "{}")}
                onEdit={edit =>
                  setCurrentProperty({
                    ...currentProperty,
                    value: JSON.stringify(edit.updated_src, null, 2),
                  })
                }
                onAdd={add =>
                  setCurrentProperty({
                    ...currentProperty,
                    value: JSON.stringify(add.updated_src, null, 2),
                  })
                }
                onDelete={del =>
                  setCurrentProperty({
                    ...currentProperty,
                    value: JSON.stringify(del.updated_src, null, 2),
                  })
                }
              />
            )}
          </Form.Item>
        )}
        {currentProperty.type === "list" && (
          <Form.Item label="Value">
            <EditableList
              listItems={currentProperty.value}
              onChange={(newList: any) =>
                setCurrentProperty({
                  ...currentProperty,
                  value: newList,
                })
              }
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default PropertyModal
