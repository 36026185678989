import Axios from "axios"
import toastr from "toastr"

export const bulkUploadSocialDeactivate = async formData => {
  toastr.success("Action Started!!")
  try {
    let data = await Axios.post(`/api/socials/bulk/deactivate`, formData)
    return data
  } catch (error) {
    toastr.error("Error in bulk deactivating socials")
    throw error
  }
}

export const uploadFile = async payload => {
  try {
    let { data } = await Axios.post(`/api/socials/uploadFile`, payload)
    toastr.success("File uploaded Successfully")
    return data
  } catch (error) {
    toastr.error("Error while Uploading File " + String(error))
    throw error
  }
}