import React, { useState, useEffect } from "react"
import { Button, Pagination, PaginationProps, Tooltip } from "antd"
import { MdEdit } from "react-icons/md"
import { Table } from "reactstrap"
import toastr from "toastr"
import {
  getStaticDataList,
  createStaticData,
  updateStaticData,
} from "clientServices/staticDataService"
import { StaticProperty } from "util/types/types"
import { RouteComponentProps, withRouter } from "react-router-dom"
import Chips from "components/CommonFilter/chips"
import Auxiliary from "util/Auxiliary"
import PropertyModal from "components/StaticData/PropertyModal"

interface Filter {
  currentPage: number
  pageSize: number
  propertyName?: string
}

interface StaticItem {
  id: number
  name: string
  type: string
  value: string
}

const StaticPropertiesPage: React.FC<RouteComponentProps> = ({
  location,
  history,
}) => {
  const urlQueryParams = new URLSearchParams(location.search)
  const initialCurrentPage = parseInt(urlQueryParams.get("currentPage") ?? "1")
  const initialPageSize = parseInt(urlQueryParams.get("pageSize") ?? "10")
  if (!urlQueryParams.get("currentPage")) {
    urlQueryParams.set("currentPage", "1")
  }

  if (!urlQueryParams.get("pageSize")) {
    urlQueryParams.set("pageSize", "10")
    history.push(`${location.pathname}?${urlQueryParams.toString()}`)
  }

  const [loading, setLoading] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [properties, setProperties] = useState<StaticProperty[]>([])
  const [modalVisible, setModalVisible] = useState(false)
  const [currentProperty, setCurrentProperty] = useState<StaticProperty>({
    name: "",
    type: "text",
    value: "",
  })
  const [propertiesTotal, setPropertiesTotal] = useState<number>(1)
  const [filters, setFilters] = useState<Filter>(() => {
    const propertyName = urlQueryParams.get("propertyName")
    return {
      currentPage: initialCurrentPage,
      pageSize: initialPageSize,
      ...(propertyName ? { propertyName } : {}),
    }
  })

  const fetchProperties = async (newFilters: Filter) => {
    try {
      setLoading(true)
      const data = await getStaticDataList(newFilters)
      setProperties(
        data.rows.map((item: StaticItem) => ({
          id: item.id,
          name: item.name,
          type: item.type,
          value:
            item.type === "list"
              ? item.value.split(",")
              : item.type === "json"
              ? JSON.stringify(item.value, null, 2)
              : item.value,
        }))
      )
      setPropertiesTotal(data.total)
    } catch (error) {
      toastr.error("Error fetching properties: " + String(error))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProperties(filters)
  }, [])

  const handleAddOrEdit = async () => {
    if (!currentProperty.name || !currentProperty.value) {
      toastr.warning("Please provide name and value before you proceed")
      return
    }
    setModalVisible(false)
    try {
      const propertyData: { name?: string; type?: string; value: any } = {
        value:
          currentProperty.type === "list"
            ? Array.isArray(currentProperty.value)
              ? currentProperty.value.join(",")
              : ""
            : currentProperty.type === "json"
            ? JSON.parse(currentProperty.value as string)
            : currentProperty.value,
      }

      if (currentProperty.id) {
        // Update property
        await updateStaticData(currentProperty.id, propertyData)
        toastr.success("Property updated successfully!")
      } else {
        // Create new property
        propertyData.name = currentProperty.name
        propertyData.type = currentProperty.type
        await createStaticData(propertyData)
        toastr.success("Property created successfully!")
      }

      fetchProperties(filters)
    } catch (error) {
      toastr.error("Error saving property: " + String(error))
    }
  }

  const handlePageChange: PaginationProps["onChange"] = async pageNumber => {
    urlQueryParams.set("currentPage", String(pageNumber))
    history.push(`${location.pathname}?${urlQueryParams.toString()}`)
    setFilters({ ...filters, currentPage: pageNumber })
    await fetchProperties({ ...filters, currentPage: pageNumber })
  }

  const _removeFilter = async (filterKey: keyof Filter) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete(filterKey)
    history.push(`${location.pathname}?${queryParams.toString()}`)
    const updatedFilters = { ...filters }
    delete updatedFilters[filterKey]
    setFilters(updatedFilters)
    await fetchProperties(updatedFilters)
  }

  const _handleFilterChange = (value: string | number, type: keyof Filter) => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.set("currentPage", "1")

    queryParams.set(type, String(value))
    history.push(`${location.pathname}?${queryParams.toString()}`)

    setFilters(prevFilters => ({
      ...prevFilters,
      [type]: value,
      currentPage: 1,
    }))
  }

  const _toggleFilters = () => {
    setShowFilter(!showFilter)
  }

  const _closeFilters = async () => {
    await fetchProperties(filters)
    setShowFilter(false)
  }

  return (
    <Auxiliary loading={loading}>
      <Chips
        showFilters={showFilter}
        filters={filters}
        search={_closeFilters}
        removeFilter={_removeFilter}
        handleFilterChange={_handleFilterChange}
        type="static_data_properties"
        toggleFilters={_toggleFilters}
      />
      <div className="text-sm-end">
        <Button
          type="primary"
          onClick={() => {
            setCurrentProperty({ name: "", type: "text", value: "" })
            setModalVisible(true)
          }}
        >
          Add New Property
        </Button>
      </div>
      <div
        className="mt-md-3 bg-white"
        style={{
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <Table hover responsive className="mt-3 h5">
          <thead>
            <tr style={{ backgroundColor: "#fff", color: "#6b7280" }}>
              <th>Name</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {properties.length > 0 ? (
              properties.map((property, index) => (
                <tr key={property.id} style={{ backgroundColor: "#fff" }}>
                  <td>{property.name}</td>
                  <td>{property.type}</td>
                  <td>
                    <Tooltip title="View or Edit Value">
                      <MdEdit
                        style={{
                          color: "#ef4444",
                          cursor: "pointer",
                        }}
                        onClick={e => {
                          e.stopPropagation()
                          setCurrentProperty(property)
                          setModalVisible(true)
                        }}
                      />
                    </Tooltip>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} style={{ textAlign: "center" }}>
                  No properties available
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="d-flex justify-content-end pb-3">
          <Pagination
            className="mt-md-3"
            onChange={handlePageChange}
            current={filters.currentPage}
            total={propertiesTotal}
            pageSize={filters.pageSize}
            showSizeChanger={false}
          />
        </div>
      </div>

      <PropertyModal
        visible={modalVisible}
        currentProperty={currentProperty}
        onCancel={() => setModalVisible(false)}
        onSave={handleAddOrEdit}
        setCurrentProperty={setCurrentProperty}
      />
    </Auxiliary>
  )
}

export default withRouter(StaticPropertiesPage)
