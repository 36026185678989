// RewardForm.tsx
import React from "react"
import { Form, Input, Switch } from "antd"
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Row,
  Col,
} from "reactstrap"
import { RewardListAPIResponse } from "util/types/types"

interface RewardsFormProps {
  reward?: RewardListAPIResponse | null
  onSave: (values: any) => void
  onCancel: () => void
}

const RewardsForm: React.FC<RewardsFormProps> = ({
  reward,
  onSave,
  onCancel,
}) => {
  const [form] = Form.useForm()
  form.setFieldsValue(
    reward || { name: "", ad_count: "", jems_count: "", active: true }
  )

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      onSave(values)
    } catch (error) {
      console.error("Validation error:", error)
    }
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-3">
          {reward ? "Update Reward Streak" : "Create Reward Streak"}
        </CardTitle>
        <CardText>
          <Form form={form} layout="vertical">
            <Row>
              <Col sm={12} md={6}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Please enter a name" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={12} md={6}>
                <Form.Item
                  name="ad_count"
                  label="Ads Count"
                  rules={[
                    { required: true, message: "Please enter ads count" },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <Form.Item
                  name="jems_count"
                  label="Jems Count"
                  rules={[
                    { required: true, message: "Please enter jems count" },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col sm={12} md={6}>
                <Form.Item label="Status" valuePropName="checked" name="active">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-end">
                <Button color="primary" onClick={handleSubmit}>
                  {reward ? "Update" : "Create"}
                </Button>
                <Button color="danger" onClick={onCancel} className="mx-2">
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </CardText>
      </CardBody>
    </Card>
  )
}

export default RewardsForm
