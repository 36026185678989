import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Input, Divider, Select } from "antd"
import { Col, Row } from "reactstrap"

type RewardsStreakDrawerProps = RouteComponentProps & {
  filters: Record<string, string>
  handleFilterChange: (value: string, key: string) => void
}

const RewardsStreakDrawer: React.FC<RewardsStreakDrawerProps> = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Status</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Select
                  allowClear={true}
                  defaultValue={props.filters.status}
                  placeholder="Status"
                  style={{ width: "100%" }}
                  onChange={value => props.handleFilterChange(value, "status")}
                  options={[
                    { label: "Active", value: "active" },
                    { label: "Inactive", value: "inactive" },
                  ]}
                ></Select>
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(RewardsStreakDrawer)
