import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Tooltip } from "antd"
import toastr from "toastr"
import { MdEdit, MdDelete } from "react-icons/md"
import { RewardListAPIResponse } from "util/types/types"
import { Badge } from "reactstrap"
import { RootStateOrAny, useSelector } from "react-redux"
import _ from "lodash"

interface RewardsCardProps {
  data: RewardListAPIResponse
  onToggleStatus: () => void
  onEdit: () => void
  onDelete: () => void
}

const RewardsCard: React.FC<RewardsCardProps> = props => {
  const handleToggleActive = () => {
    props.onToggleStatus()
    toastr.success(
      `Reward Streak ${props.data.id} is now ${
        props.data.active ? "Deactivated" : "Activated"
      }`
    )
  }
  
  const {
    currentUser: { data },
  } = useSelector((state:RootStateOrAny) => state.currentUser)

  const userPermissions = _.values(data.jotPermissions)
  
  return (
    <Card
      className="text-center shadow-sm align-self-center"
      style={{ cursor: "pointer", width: "200px" }}
      onClick={() => {
        props.onEdit()
      }}
    >
      <CardBody>
        <div className="avatar-md mb-4 m-auto">
          <span
            className="avatar-title rounded-circle bg-gradient font-size-24 font-weight-semibold"
            style={{ backgroundColor: "#00a3ff" }}
          >
            {props.data.name.charAt(0).toUpperCase()}
          </span>
        </div>
        <CardTitle className="font-size-18 card-title">
          {props.data.name}
        </CardTitle>

        {props.data.ad_count && (
          <Tooltip title="Ads count" style={{ minHeight: "20px" }}>
            <Badge
              className={`${"badge-soft-info"} ms-1 font-size-13 my-2 text-black me-1`}
            >
              <b>Ads count: </b>
              {props.data.ad_count}
            </Badge>
          </Tooltip>
        )}

        {props.data.jems_count ? (
          <Tooltip title="Jems count" style={{ minHeight: "20px" }}>
            <Badge
              className={`${"badge-soft-success"} ms-1 font-size-13 my-2 text-black me-1`}
            >
              <b>Jems count: </b>
              {props.data.jems_count}
            </Badge>
          </Tooltip>
        ) : null}
      </CardBody>
      <div
        className="d-flex font-size-20 p-2 border-top text-center"
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <div className="flex-fill">
          <CopyToClipboard
            text={props.data.id}
            onCopy={() => toastr.success("Copied successfully!")}
          >
            <Tooltip title="Copy ID">
              <i className="bx bx-copy-alt" role="button" />
            </Tooltip>
          </CopyToClipboard>
        </div>
        <div className="flex-fill" onClick={() => props.onEdit()}>
          <Tooltip title="Edit">
            <MdEdit style={{ cursor: "pointer" }} />
          </Tooltip>
        </div>
        <div className="flex-fill">
          {props.data.active ? (
            <Tooltip title="Deactivate">
              <i
                className="bx bx-block"
                role="button"
                style={{ color: "red" }}
                onClick={e => {
                  e.stopPropagation()
                  handleToggleActive()
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Activate">
              <i
                className="bx bx-comment-error"
                role="button"
                style={{ color: "green" }}
                onClick={e => {
                  e.stopPropagation()
                  handleToggleActive()
                }}
              />
            </Tooltip>
          )}
        </div>
        {
          userPermissions.includes("DELETE_REWARDS_STREAK") && 
          <div className="flex-fill" onClick={() => props.onDelete()}>
            <Tooltip title="Delete" className="mb-2">
              <MdDelete style={{ cursor: "pointer", color: "red" }} />
            </Tooltip>
          </div>
        }
      </div>
    </Card>
  )
}

export default RewardsCard
