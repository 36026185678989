import React from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Input, Divider } from "antd"
import { Col, Row } from "reactstrap"

type StaticDataDrawerProps = RouteComponentProps & {
  filters: Record<string, string>
  handleFilterChange: (value: string, key: string) => void
}

const StaticDataDrawer: React.FC<StaticDataDrawerProps> = props => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col lg="3" md="3">
          <Divider>
            <h5 className="font-size-11 mb-0 text-muted">Property Name</h5>
          </Divider>
          <Input.Group compact={true}>
            <Row className="w-100">
              <Col className="d-flex">
                <Input
                  defaultValue={props.filters["propertyName"]}
                  placeholder={"Property Name"}
                  onChange={e =>
                    props.handleFilterChange(
                      e.target.value.trim(),
                      "propertyName"
                    )
                  }
                  allowClear={true}
                  name="property_name"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(StaticDataDrawer)
